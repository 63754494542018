@font-face {
  font-family: "ageer";
  src: url("./assets/ageer2/Ageer-PersonalUse.otf");
  /* src: local("Euclid Circular A Bold"), local("EuclidCircularA-Bold"),
    url("./assets/font/EuclidCircularA-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Bold.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Bold.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Bold.ttf") format("truetype"); */
  font-weight: bold;
  font-style: normal;
}



::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #FD5709;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: #FD5709;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #14AE5C;
}
.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.ql-editor .Wirisformula {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}
